import { defineStore } from "pinia"
import { GetAdGroupTag, GetKeywordTag, GetAllAsinTags, GetMaxDateTime } from "@/api/Advertising/advertising"
import { IsCurrentUserCampaignTagPermissionOn, getCampaignTags } from "@/api/Advertising"
import { useCommonStore } from "@/store/index"
import { dayjs } from "@pacvue/element-plus"

const useAdvertisingStore = defineStore("advertisingStore", {
  state: () => {
    return {
      adgroupTagList: [],
      keywordTagList: [],
      asinTagList: [],
      campaignTagList: [],
      feadTodayCache: {}
      //存储全部的tag信息
      // allAsinTagList: [],
      // allCampaignTagList: []
    }
  },
  getters: {},
  actions: {
    getCampaignTagList(playload) {
      var clientIdArray = ["62", "495", "506"]
      var commonStore = useCommonStore()
      var profileIds = commonStore.adProfileIds || []
      if (!playload && this.campaignTagList.length) {
        return Promise.resolve(this.campaignTagList)
      }
      return new Promise((resolve) => {
        getCampaignTags({ profileIds }).then((dataList) => {
          var treeData = []

          treeData.push(...dataList)
          this.campaignTagList = treeData
          resolve(treeData)
        })
      })
    },
    //获取AdgroupTag
    GetAdGroupTagList(playload) {
      return GetAdGroupTag(playload).then((data) => {
        this.adgroupTagList = data || []
        return data || []
      })
    },
    //获取KeywordTag
    GetKeywordTagList(playload) {
      return GetKeywordTag(playload).then((data) => {
        this.keywordTagList = data || []
        return data || []
      })
    },
    //获取AsinTag
    GetAsinTagList(playload) {
      var commonStore = useCommonStore()
      var profileIds = commonStore.adProfileIds || []
      if (this.asinTagList.length) {
        return Promise.resolve(this.asinTagList)
      }
      if (!playload) {
        playload = { profileIds }
      }
      playload = {}
      return GetAllAsinTags(playload).then((data) => {
        this.asinTagList = data || []
        return data || []
      })
    },
    async PacvueGetMaxDateTime(query) {
      var profileIds = query.profileIds || []
      var params = {
        profileIds: profileIds,
        shareId: "",
        toMarket: "US"
      }
      var isFeadTodayId = "isFeadToday_" + JSON.stringify(profileIds)
      var isFeadTodayCache = this.feadTodayCache[isFeadTodayId]
      var startDate = query.start
      var endDate = query.end
      if (isFeadTodayCache) {
        startDate = isFeadTodayCache.startDate
        endDate = isFeadTodayCache.endDate
        return { start: startDate, end: endDate }
      }
      var date = await GetMaxDateTime(params)
      var time = new Date(date).getTime()
      var start = new Date(dayjs().format("MM/DD/YYYY")).getTime()
      if (start - 24 * 60 * 60 * 1000 < time) {
        startDate = date.split(" ")[0]
        endDate = date.split(" ")[0]
      }
      this.feadTodayCache[isFeadTodayId] = { startDate, endDate }
      return { start: startDate, end: endDate }
    }
  }
})

export default useAdvertisingStore
