import { getSettings } from "@/api/account"
import { getUserGuidanceStep } from "@!/api/Account/setting"
import { dayjs } from "@pacvue/element-plus"

if (localStorage.getItem("locale") === "ZH") {
  dayjs.locale("zh-cn")
  dayjs.updateLocale("zh-cn", {
    weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
    yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
  })
} else {
  dayjs.locale("en")
  dayjs.updateLocale("en", {
    weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
    yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
  })
}
export default {
  settingInterceptors: [
    (context, to, from) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const setting = await getSettings(context.user.userId)
          // 是否完成第一步偏好设置 (平台无关)
          if (setting?.dateFormat) {
            localStorage.setItem(`${context.user.userId}_SettingStep`, 1)
            // 渲染主题
            if (setting.themeColour == "white") {
              document.getElementsByTagName("body")[0].setAttribute("class", "theme1")
            } else if (setting.themeColour == "black") {
              document.getElementsByTagName("body")[0].setAttribute("class", "theme2 borderversion")
            } else {
              document.getElementsByTagName("body")[0].setAttribute("class", "theme2")
            }
            // 进一步判断进行到第二步还是第三步引导设置 (平台独立)
            const res = await getUserGuidanceStep()
            const settingStep = res?.step ?? 1
            localStorage.setItem(`${context.user.userId}_SettingStep`, settingStep)
            localStorage.setItem(`${context.user.userId}_SettingState`, settingStep == 3 ? 1 : 0)
            localStorage.setItem(`${context.user.userId}_SettingStore`, res?.includeStore)
            localStorage.setItem(`${context.user.userId}_SettingDisplay`, res?.includeDisplay)
          } else {
            localStorage.setItem(`${context.user.userId}_SettingState`, 0)
            localStorage.setItem(`${context.user.userId}_SettingStep`, 0)
            localStorage.setItem(`${context.user.userId}_SettingStore`, "")
            localStorage.setItem(`${context.user.userId}_SettingDisplay`, "")
          }
          // 这边的判断逻辑不够严谨
          // if (VITE_APP_APIENV == "us" && VITE_APP_NODE_ENV !== "development") {
          //   localStorage.setItem("locale", "EN")
          //   setting.language = "EN"
          // }
          let locale = localStorage.getItem("locale")
          if (!locale || locale !== setting.language) {
            window.prefLangDiffered = true
            localStorage.setItem("locale", setting.language)
            // if (i18n.mode === "legacy") {
            //   i18n.global.locale = setting.language
            // } else {
            //   i18n.global.locale.value = setting.language
            // }
            // sessionStorage.clear()
            // location.reload()
          }
          var elementLocale = "en"
          if (localStorage.getItem("locale") === "ZH") {
            elementLocale = "zh-cn"
          } else {
            elementLocale = "en"
          }
          dayjs.updateLocale(elementLocale, {
            weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
            yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
          })
          context.SET_USERSETTINGS({ ...(setting || {}), defaultCurrency: "US" })
          onFulfill(setting)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ],
  loginInterceptors: function (router) {
    router.push(window.HomePage || "/Home")
  }
}
