import request from "@pacvue/frame/request"
const { VITE_APP_WALMART_BASE_URL } = import.meta.env

export function profilesWithCampaigns() {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "User/profilesWithCampaigns",
    method: "get"
  })
}
export function fetchCampaign(data, query) {
  var option = {
    url: VITE_APP_WALMART_BASE_URL + "User/campaignsForZip",
    data,
    method: "post"
  }
  if (query) {
    option.params = query
  }
  return request(option)
}
export function fetchFullCampaign(data, query) {
  var option = {
    url: VITE_APP_WALMART_BASE_URL + "/User/campaigns",
    method: "post",
    data
  }
  if (query) {
    option.params = query
  }
  return request(option)
}
export function CheckSupportInfo() {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "User/CheckSupportInfo",
    method: "get"
  })
}
export function SupportSplitAttributes() {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "Report/SupportSplitAttributes",
    method: "get"
  })
}

//二次登录相关
export function loginBindMFA(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "​/Login​/bindMFA",
    method: "post",
    headers: {},
    data
  })
}

export function loginVerifyMFA(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "/Login/verifyMFA",
    method: "post",
    headers: {},
    data
  })
}

export function sendVerificationCode(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "/Login/sendVerificationCode",
    method: "post",
    headers: {},
    data
  })
}

export function getSecret() {
  return request({
    url: `/User/getSecret`,
    method: "GET"
  })
}
export function getSOVCategory(params) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "User/SOVCategory",
    method: "get",
    params
  })
}
