import request from "@pacvue/frame/request"
const VITE_APP_BASEURL = import.meta.env.VITE_APP_BASEURL
const VITE_APP_SOV2 = import.meta.env.VITE_APP_SOV2
const VITE_APP_META = import.meta.env.VITE_APP_META
var VITE_APP_DOWNLOAD = import.meta.env.VITE_APP_DOWNLOAD

const productUrl = VITE_APP_SOV2
export function GetAdvertisingPageDataTotal(data, url) {
  var baseUrl = VITE_APP_BASEURL
  if (url == "AsinInfo/GetAsinTagDataTotal") {
    baseUrl = VITE_APP_SOV2
  }
  return request({
    url: baseUrl + url,
    data,
    method: "post"
  })
}

export function GetAdvertisingChartData(data, url) {
  url = url || "Profile/v3/GetProfileChartData"
  var baseUrl = VITE_APP_BASEURL
  if (url == "AsinInfo/GetAsinTagChartData") {
    baseUrl = VITE_APP_SOV2
  }
  return request({
    url: baseUrl + url,
    data,
    method: "post"
  })
}

export function GetAdvertisingPageData(data, url) {
  url = url || "Profile/v3/GetProfilePageData"

  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function DownloadAdvertisingPageData(data, url) {
  url = url || "Profile/v3/GetProfilePageData"
  var baseUrl = VITE_APP_DOWNLOAD
  if (url == "AsinInfo/GetAsinTagPageDataDownload") {
    baseUrl = VITE_APP_SOV2
  }
  return request({
    url: baseUrl + url,
    data,
    method: "post"
  })
}
export function GetCampaignPageDataChild(data) {
  var url = "Campaign/v3/GetCampaignPageDataChild"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetPlacementCampaignPageDataChild(data) {
  var url = "Campaign/v3/GetPlacementCampaignPageDataChild"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetMaxDateTime(data) {
  return request({
    url: VITE_APP_BASEURL + "Fead/v3/GetMaxDateTime",
    data: data,
    method: "post"
  })
}

export function GetCampaignSubTagData(data) {
  var url = "CampaignTag/v3/GetCampaignSubTagData"
  var dim = data.dim
  if (dim != "ytd") {
    url = "CampaignTag/v3/GetCampaignSubTagGroupByData"
  }
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}

export function GetAdGroupSubTagData(data) {
  var url = "AdGroupTag/v3/GetAdGroupSubTagData"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAsinSubTagPageData(data) {
  var url = "AsinInfo/GetAsinSubTagPageData"
  var baseUrl = VITE_APP_SOV2
  var dim = data.dim
  if (dim != "ytd") {
    baseUrl = VITE_APP_BASEURL
    url = "AsinTag/v3/GetAsinSubTagPageDataGroupByData"
  }
  return request({
    url: baseUrl + url,
    data,
    method: "post"
  })
}
export function GetAdGroupTag(data) {
  return request({
    url: VITE_APP_BASEURL + "Tagging/v3/GetAdGroupTag",
    method: "GET",
    data
  })
}
export function GetKeywordTag(data) {
  return request({
    url: `${VITE_APP_META}apply/keywordTags`,
    data,
    method: "post"
  })
}
export function GetAllAsinTags(params) {
  return request({
    url: VITE_APP_SOV2 + "AdObject/GetAllAsinTags",
    params,
    method: "get"
  })
}
export function getUserList(data) {
  return request({
    url: `${VITE_APP_META}apply/getUserList`,
    method: "post",
    //fullData: true,
    data: data
  })
}

export function AddCampaignToPortfolio(data) {
  let url = "Portfolio/v3/AddCampaignToPortfolio"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function CreatePortfolio(data) {
  let url = "Portfolio/v3/CreatePortfolio"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function UpdataPortfolio(data) {
  let url = "Portfolio/v3/UpdataPortfolio"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function BulkUpdatePortfolio(data) {
  let url = "Portfolio/v3/BulkUpdatePortfolio"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
//asinDialog
export function GetCampaignByCampaignType(data) {
  let url = "Campaign/v3/GetCampaignByCampaignType"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function AdGroupSkuSave(data) {
  let url = "Adgroup/v3/AdGroupSkuSave"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAdgroupsByCampaignIds(data) {
  let url = "Adgroup/v3/GetAdgroupsByCampaignIds"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAdgroupByAsin(data) {
  let url = "ProductAd/v3/GetAdgroupByAsin"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAllAsinTagsForAsin(data) {
  let url = "AdObject/GetAllAsinTagsForAsin"
  return request({
    url: productUrl + url,
    data,
    method: "get"
  })
}
export function CreateAsinTag(data) {
  let url = "AsinInfo/CreateAsinTag"
  return request({
    url: productUrl + url,
    data,
    method: "post"
  })
}
export function AddAsinsToAsinTagging(data) {
  let url = "AsinInfo/AddAsinsToAsinTagging"
  return request({
    url: productUrl + url,
    data,
    method: "post",
    fullData: true
  })
}
export function GetAsinTagsByAsin(data) {
  let url = "AsinInfo/GetAsinTagsByAsin"
  return request({
    url: productUrl + url,
    data,
    method: "post"
  })
}
export function RemoveAsinsToAsinTagging(data) {
  let url = "AsinInfo/RemoveAsinsToAsinTagging"
  return request({
    url: productUrl + url,
    data,
    method: "post",
    fullData: true
  })
}
export function GetBrandAudit(data) {
  let url = "BrandAudit/GetBrandAudit"
  return request({
    url: productUrl + url,
    data,
    method: "post"
  })
}
export function InsertAsins2BrandAudit(data) {
  let url = "BrandAudit/InsertAsins2BrandAudit"
  return request({
    url: productUrl + url,
    data,
    method: "post"
  })
}
export function BulkAdGroupSkuReplace(data) {
  let url = "Adgroup/v3/BulkAdGroupSkuReplace"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function AddCampaigns(data) {
  let url = "PortfolioFromAmz/v3/AddCampaigns"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function getCampaignByProfile(data) {
  return request({
    url: `${VITE_APP_BASEURL}Campaign/v3/GetPageCampaignByProfile`,
    method: "post",
    //fullData: true,
    data: data
  })
}
export function BulkAddAsinsToCampaignAll(data) {
  let url = "Asin/BulkAddAsinsToCampaignAll"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}

export function RemoveAsinsFromAsinTagAll(data) {
  let url = "Asin/RemoveAsinsFromAsinTagAll"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function AddAsinsToTagAll(data) {
  let url = "Asin/AddAsinsToTagAll"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function BlukAddCampaigns(data) {
  let url = "PortfolioFromAmz/v3/BlukAddCampaigns"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
