import Main from "@pacvue/frame/layout/Main.vue"
export default {
  router: {
    path: "/",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Optimization/ASINAI",
        component: () => import("@AsinCenterAIv3/indexAI.vue"),
        name: `Product AI`,
        meta: {
          title: `Product AI`,
          id: 2,
          isShowTime: false,
          showCompareCheck: false,
          identicalPath: "/Optimization/ASINAI/#/AI/index"
        }
      },
      {
        path: "/Optimization/createAI",
        component: () => import("@AsinCenterAIv3/IntroductoryAI.vue"),
        name: "CreateSuperAI",
        meta: {
          title: `Product AI`,
          id: 33,
          showCompareCheck: false,
          breadcrumb: [
            {
              name: `Product AI`,
              link: "/"
            }
          ]
        }
      },
      {
        path: "/Optimization/AI/AiDetail",
        component: () => import("@AsinCenterAIv3/detail/index.vue"),
        name: "AI Detail",
        meta: {
          title: "AI",
          id: 4,
          isShowTime: false,
          showCompareCheck: false,
          parent: `Product AI`,
          breadcrumb: [
            {
              name: "AI",
              link: "/AI/index"
            },
            {
              name: "AI Detail",
              link: ""
            }
          ]
        }
      },
      {
        path: "/Optimization/AI/LanuchAI",
        component: () => import("@AsinCenterAIv3/createAI/lanuchAI.vue"),
        name: `Launch AI for Product`,
        meta: {
          title: "Create SD Campaign intelligently",
          id: 5,
          isShowTime: false,
          showCompareCheck: false,
          parent: `Product AI`,
          breadcrumb: [
            {
              name: `Launch AI for Product`,
              link: "/"
            }
          ]
        }
      }
    ]
  }
}
