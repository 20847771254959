// 需要埋点的页面名称
export const ANALYTICS_PAGELIST = [
  "Home",
  "PC-Dashboard",
  "PC-Listing",
  "PC-Listing-Product Detail",
  "PC-Unauthorized Page",
  "BM-List",
  "BM-Dashboard",
  "BM-Settings",
  "BM-Calendar Manager",
  "BM-Operation Log",
  "Advertising-Profile",
  "Advertising-Campaign Tag",
  "Advertising-Campaign Tag Detail",
  "Advertising-Keyword Tag",
  "Advertising-Item Tag",
  "Advertising-Campaign",
  "Advertising-Campaign Pagetype",
  "Advertising-Campaign Placement",
  "Advertising-Campaign Platform",
  "Advertising-Campaign Category",
  "Advertising-Campaign Brand",
  "Advertising-Adgroup",
  "Advertising-Adgroup Pagetype",
  "Advertising-Adgroup Platform",
  "Advertising-Keyword Detail",
  "Advertising-Keyword Analysis",
  "Advertising-Item",
  "Advertising-Item Detail",
  "Advertising-Item Health",
  "Advertising-Item Analysis",
  "Advertising-Search Term",
  "Advertising-Search Term Detail",
  "Advertising-Bulk Operations",
  "Advertising-Bulk Operation Logs",
  "Search Default Report",
  "Download Default Report",
  "My Report",
  "My Report History",
  "Research-Keyword Research",
  "Research-Bid Landscape Chart",
  "Research-Query Item Graph",
  "OPT-Auto Rules",
  "OPT-Manual Rules",
  "OPT-Manual Rules Record",
  "OPT-Rule Library",
  "OPT-Campaign AI",
  "OPT-Product AI",
  "OPT-Product AI Detals",
  "OPT-Paid Search Audit",
  "OPT-Paid Search Audit Detail",
  "SOV List",
  "SOV Dashboard",
  "SOV Insight",
  "SOV Brand",
  "SOV Keyword",
  "SOV Keyword Detail",
  "SOV Item",
  "SOV Item Detail",
  "SOV Brands Mapping",
  "SOV Keyword Tag",
  "CI-Search Term",
  "CI-Product",
  "CI-Brand",
  "Advertising Event",
  "Task Center Event",
  "Task Center Event Log",
  "Notification",
  "Message",
  "My Account",
  "Profile Modified Logs",
  "User Management",
  "Settings",
  "CI-Product-Item Search Term",
  "View Download Center",
  "View Bid Explore Overview",
  "View Bid Explore overviewcpc",
  "Favorite Item",
  "Delivery Rate Analysis",
  "Product AI Detail",
  "Raw Keywords",
  // walmartv1.39
  "Campaign Explorer - Perf. - Campaign",
  "Campaign Explorer - Perf. - Hourly Data",
  "Campaign Explorer - Perf. - Platform",
  "Campaign Explorer - Perf. - Placement",
  "Campaign Explorer - Perf. - Pagetype",
  "Campaign Explorer - Perf. - Category",
  "Campaign Explorer - Perf. - Brand",
  "Campaign Explorer - Perf. - New Buyer",
  "Campaign Explorer - Adgroup",
  "Campaign Explorer - Item",
  "Campaign Explorer - Keyword",
  "Campaign Explorer - Tactic",
  "Campaign Explorer - Search Term",
  "Item - Display Ads",
  "Advertising-Targeting-Keywords"
]

// 路由名称与页面名称的映射
export const ANALYTICS_PAGE_MAPPER = {
  "Campaign Tag Detail": "Advertising-Campaign Tag Detail",
  "Keyword Detail": "Advertising-Keyword Detail",
  "Keyword Analysis": "Advertising-Keyword Analysis",
  "Item Detail": "Advertising-Item Detail",
  "Item Health": "Advertising-Item Health",
  "Item Analysis": "Advertising-Item Analysis",
  "Search Term Detail": "Advertising-Search Term Detail",
  Order: "PC-Order",
  "Eligibility Insight": "PC-Latitude",
  Adgroup: "Advertising-Adgroup",
  // Product Center 模块
  ProductDashboard: "PC-Dashboard",
  "Product Listing": "PC-Listing",
  "Product Detail": "PC-Listing-Product Detail",
  ProductNonVC: "PC-Unauthorized Page",
  // Budget 模块
  "Budget Manager": "BM-List",
  "Budget Setting": "BM-Settings",
  "Operation Log": "BM-Operation Log",
  "Calendar Template Manager": "BM-Calendar Manager",
  "Budget Dashboard": "BM-Dashboard",
  // Advertising 模块
  CampaignTagging: "Advertising-Campaign Tag",
  KeywordTagging: "Advertising-Keyword Tag",
  ItemTagging: "Advertising-Item Tag",
  Campaign: "Advertising-Campaign",
  AdvertisingItem: "Advertising-Item",
  AdvertisingSearchTerm: "Advertising-Search Term",
  AdvertisingBulkOperations: "Advertising-Bulk Operations",
  AdvertisingBulkOperationsLog: "Advertising-Bulk Operation Logs",
  // My Report 模块
  "View History": "My Report History",
  // Optimization 模块
  "Manual Rule Run Record": "OPT-Manual Rules Record",
  "Campaign AI": "OPT-Campaign AI",
  "Product AI": "OPT-Product AI",
  "Paid Search Audit": "OPT-Paid Search Audit",
  "Audit Detail": "OPT-Paid Search Audit Detail",
  // SOV 模块
  Competitive: "SOV List",
  // SOVInsight: "SOV Insight",
  "View SOV": "SOV Dashboard",
  SOVBrand: "SOV Brand",
  SOVKeyword: "SOV Keyword",
  KeywordDetail: "SOV Keyword Detail",
  SOVItem: "SOV Item",
  "Item SOV Analysis": "SOV Item Detail",
  BrandsMapping: "SOV Brands Mapping",
  "Manage SOV Keyword Tag": "SOV Keyword Tag",
  // CI 模块
  SearchTerm: "CI-Search Term",
  Product: "CI-Product",
  BrandAndSales: "CI-Brand",
  // event 模块
  // "Advertising Event": "Advertising Event",
  "Task Center": "Task Center Event",
  // Research模块
  "Keyword Research": "Research-Keyword Research",
  "Bid LandScape": "Research-Bid Landscape Chart",
  "Query-Item Graph": "Research-Query Item Graph",

  Setting: "Settings",
  UserManagement: "User Management",
  MyAccount: "My Account",
  AdvertisingExplore: "Advertising-Explorer",
  CampaignProfile: "Advertising-Profile",
  // ProfileDetail: "Advertising-profile details",
  // CampaignTagging: "Advertising-Campaign tag",
  AdgroupTagging: "Advertising-adgroup",

  ASINTagging: "Advertising-asin tag",
  AmazonPortfolio: "Advertising-portfolio",
  // PortfolioDetail: "Advertising-portfolio details",
  // Campaign: "Advertising-campaign",
  "Hourly Trend Detail": "Advertising-campaign hourly data",
  CampaignAsin: "Advertising-ASIN",
  "Negative Targeting": "Advertising-NG Targeting",
  CampaignBulkOperations: "Advertising-Bulk operations",
  CampaignBulkOperationsLog: "Advertising-Bulk operation logs",
  CampaignSearchTerm: "Advertising-ST",
  Targeting: "Advertising-Targeting",
  CampaignAdGroup: "Advertising-adgroup",
  SbAds: "Advertising-adgroup SB ads",
  CreateSBAd: "Advertising-SB ads creative",
  PATResearch: "Research-PAT Research",
  "Audience Research": "Research-Audience Research",
  QueryAsinGraph: "Research-Query-ASIN Graph",
  "Asin LandScape": "Research-ASIN-landscape Chart",
  "Dayparting Scheduler": "OPT-Dayparting Scheduler",
  "Apply Detail": "OPT-Dayparting Scheduler Details",
  "Budget Scheduler": "OPT-Budget Scheduler",
  "Budget Detail": "OPT-Budget Scheduler Details",
  Bidexplorer: "OPT-Bid Explorer",
  BidexplorerOverview: "OPT-Bid Explorer Overview",

  PortfolioDetail: "Advertising-profile details",

  "Audited ASIN": "Brand Audit ASIN List",
  "Sponsored Display Dashboard": "SD Dashboard",
  "ASIN SOV Analysis": "SOV ASIN Details",
  "Analytics DashBoard": "Analytics Dashboard",
  AsinTag: "Advertising-asin tag",
  Products: "Incrementality Product Page",
  Campaigns: "Incrementality Campaign Page",
  ASINHistory: "CI-Product-Item Search Term",
  ASINQuery: "CI-Product-Item Search Term",
  Download: "Download Center",
  BidexplorerOverviewCpc: "Bid Explore overviewcpc"
}

// 不需要路由守卫自动触发的页面
export const IGNORE_ANALYTICS_PAGELIST = [
  "Advertising-Campaign Tag Detail",
  "Advertising-Keyword Detail",
  "Advertising-Item Detail",
  "PC-Listing",
  "Advertising Event",
  "Task Center Event",
  "Message",
  "Advertising-Campaign Tag",
  "Advertising-Keyword Tag",
  "Advertising-Item Tag",
  "Advertising-Campaign",
  "Default Report",
  "Profile Report",
  "Keyword Report",
  "Account Profile Spend Report",
  "Item Report",
  "Item Health Report",
  "Campaign Report",
  "Pagetype Report",
  "Placement Report",
  "Platform Report",
  "Campaign Hourly Report",
  "Campaign Out of Daily Budget Report",
  "Campaign Tag Report",
  "CI-Product-Item Search Term",
  "OPT-Campaign AI",
  "Advertising Event",
  "Advertising-Campaign",
  "Advertising-Campaign Pagetype",
  "Advertising-Campaign Placement",
  "Advertising-Campaign Platform",
  "Advertising-Campaign Category",
  "Advertising-Campaign Brand",
  "Advertising-Adgroup",
  "OPT-Paid Search Audit Detail",
  "SOV Insight"
]

// 域名对应的region
export const hostRegionMapper = {
  "walmart.pacvue.com": "COM",
  "walmart.pacvue.cn": "CN",
  "product.pacvue.com/walmart": "COM",
  "product.pacvue.cn/walmart": "CN"
}
