import { getRuleAction } from "@/api/rule/rulelist"
import { getAllRequirementMetrics } from "@/api/rule/ruleLibrary"
import { getCommercePermission, getKeywordTags, getItemTags, getASINTags, getRuleTypes, getActionMetrics } from "@/api/rule/rule"

const productline = localStorage.getItem("productline")

const set2Obj = (dataArr) => {
  let data = {}
  dataArr.forEach((e) => {
    if (data[e.ruleType]) {
      data[e.ruleType].push(e)
    } else {
      data[e.ruleType] = [e]
    }
  })
  return data
}

const state = () => {
  return {
    RuleTypeList: [],
    ActionMetrics: {},
    TagsObj: {},
    AllRequirementMetricsObj: {},
    RequirementConfig: [],
    ActionConfig: [],
    TargetTypeIsChange: false,

    IsCommerce: "no" // use see no
  }
}

const getters = {
  RuleTypeOptionsGetter: (state) => {
    return state.RuleTypeList.map((item) => ({ label: `${item.ruleType} Rule`, value: item.ruleType }))
  }
}

const actions = {
  /**
   * 初始化数据
   *
   */
  async initRuleStates({ noAuth } = {}) {
    const productline = localStorage.getItem("productline")

    if (!this.RuleTypeList.length) this.GetRuleTypeList()
    if (JSON.stringify(this.ActionMetrics).length < 10) await this.GetRuleActionMetrics()
    if (JSON.stringify(this.TagsObj) == "{}") await this.GetTagsObj()
    if (JSON.stringify(this.AllRequirementMetricsObj) == "{}") await this.GetAllRequirementMetricsObj()
    return true
  },

  async GetRuleTypeList() {
    const data = await getRuleTypes()
    this.RuleTypeList = data
    return data
  },

  /**
   * IsCommerce commerce rule权限
   * use 可用
   * see 仅显示tab标签，提示可开
   * no 无权限 隐藏tab
   */
  async GetCommercePermission() {
    const data = await getCommercePermission()
    this.IsCommerce = data
  },

  /**
   * requirements tag选项
   * 不同平台 item keyword asin tags
   */
  async GetTagsObj() {
    const KeywordTag = (["instacart", "amazon", "walmart", "samsclub"].includes(productline) && (await getKeywordTags())) || {}
    const ASINTag = (productline == "amazon" && (await getASINTags())) || {}
    const ItemTag = (["walmart", "samsclub"].includes(productline) && (await getItemTags())) || {}
    const data = { KeywordTag, ASINTag, ItemTag }
    this.TagsObj = data
    return data
  },
  /**
   * requirements options (后端配置)
   */
  async GetAllRequirementMetricsObj() {
    const data = await getAllRequirementMetrics()
    const obj = set2Obj(data || [])
    this.AllRequirementMetricsObj = obj
    return obj
  },

  /**
   * format action映射关系
   * ruletype: { detail, title } (后端配置 )
   */
  async GetRuleActionMetrics() {
    const data = await getRuleAction()
    this.ActionMetrics = data || {}
    return data
  },

  /**
   * action options (后端配置)
   */
  async getActionConfig(ruleType) {
    const data = await getActionMetrics({ ruleType })
    this.ActionConfig = data
    return data
  },

  /**
   * 单个rule requirement 配置，有特殊情况需要特殊处理
   */
  Set_REQUIREMENT_CONFIG(data) {
    this.RequirementConfig = data
  },
  SET_ACTION(configObj) {
    // console.log(this, configObj, 111)
    this.ActionConfig = configObj
  },
  /**
   * 跨多组件传值
   * 初始化actionView时 ruleTargetType == "Automatic" adgroup action要分组
   */
  SET_TARGETTYPE_ISCHANGE(value) {
    this.TargetTypeIsChange = value
  }
}

export default {
  state,
  getters,
  actions
}
