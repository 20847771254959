import { defineStore } from "pinia"
import { GetAdGroupTag, GetKeywordTag, GetMaxDateTime } from "@/api/Advertising/advertising"
import { getCampaignTags } from "@!/api/Advertising/CampaignTag"
import { getItemTags } from "@!/api/Advertising/ItemTag"
import { CheckSupportInfo } from "@!/api/User"
import { PacvueGetPathInfo, useUserInfo, getQuery } from "@/utils/common-util"
import { useCommonStore } from "@/store/index"
import { useRoute } from "vue-router"
import { dayjs } from "@pacvue/element-plus"

const useAdvertisingStore = defineStore("advertisingStore", {
  state: () => {
    return {
      adgroupTagList: [],
      keywordTagList: [],
      itemTagList: [],
      campaignTagList: [],
      feadTodayCache: {},
      isSB: false,
      supportInfo: null
      //存储全部的tag信息
      // allAsinTagList: [],
      // allCampaignTagList: []
    }
  },
  getters: {
    isSupportSplitAttributes() {
      var $route = useRoute()
      //是否支持裂开支持
      var supportInfo = this.supportInfo || {}
      var isSupportSplitAttributes = supportInfo.isSupportFieldSplit || false
      var menuList = ["profile", "campaigntagging", "campaigntag", "itemtagging", "itemtag", "campaign", "adgroup", "item"]
      var pathInfo = PacvueGetPathInfo({ pathname: $route.path })
      var lastMenuLower = pathInfo.lastMenuLower
      //console.error(">>>>>>>>>>>lastMenuLower", pathInfo, lastMenuLower, isSupportSplitAttributes)
      if (lastMenuLower == "detail") {
        var query = getQuery() || {}
        lastMenuLower = (query.parentMenu || query.mode || "").toLowerCase()
        //Targeting KeywordAnalysis CampaignTag Item ItemAnalysis
      }
      if (isSupportSplitAttributes && menuList.includes(lastMenuLower)) {
        return true
      }
      return false
    }
  },
  actions: {
    getCampaignTagList(playload) {
      var commonStore = useCommonStore()
      var profileIds = commonStore.adProfileIds || []
      if (!playload && this.campaignTagList.length) {
        return Promise.resolve(this.campaignTagList)
      }
      return new Promise((resolve) => {
        getCampaignTags("Campaign", { profileIds }).then((dataList) => {
          var treeData = []
          treeData.push(...dataList)
          this.campaignTagList = treeData
          resolve(treeData)
        })
      })
    },
    //获取AdgroupTag
    GetAdGroupTagList(playload) {
      return GetAdGroupTag(playload).then((data) => {
        this.adgroupTagList = data || []
        return data || []
      })
    },
    //获取KeywordTag
    GetKeywordTagList(playload) {
      var userInfo = useUserInfo()
      var toMarket = userInfo.dc.value
      return GetKeywordTag({ toMarket: toMarket }).then((data) => {
        this.keywordTagList = data || []
        return data || []
      })
    },
    //获取ItemTag
    GetItemTagList(playload) {
      var commonStore = useCommonStore()
      var profileIds = commonStore.adProfileIds || []
      if (this.itemTagList.length) {
        return Promise.resolve(this.itemTagList)
      }
      if (!playload) {
        playload = { profileIds }
      }
      playload = {}
      return getItemTags().then((data) => {
        this.itemTagList = data || []
        return data || []
      })
    },
    async GetCheckSupportInfo() {
      var supportRes = {}
      try {
        supportRes = (await CheckSupportInfo()) ?? {}
      } catch (ex) {
        supportRes = {}
      }
      // var isSupportSplitAttributes = await SupportSplitAttributes()
      this.supportInfo = {
        ...supportRes
        // isSupportSplitAttributes
      }
      localStorage.setItem("Advertising_supportInfo", JSON.stringify(supportRes))
    },
    async PacvueGetMaxDateTime(query) {
      var profileIds = query.profileIds || []
      var params = {
        profileIds: profileIds,
        shareId: "",
        toMarket: "US"
      }
      var isFeadTodayId = "isFeadToday_" + JSON.stringify(profileIds)
      var isFeadTodayCache = this.feadTodayCache[isFeadTodayId]
      var startDate = query.start
      var endDate = query.end
      if (isFeadTodayCache) {
        startDate = isFeadTodayCache.startDate
        endDate = isFeadTodayCache.endDate
        return { start: startDate, end: endDate }
      }
      var date = await GetMaxDateTime(params)
      var time = new Date(date).getTime()
      var start = new Date(dayjs().format("MM/DD/YYYY")).getTime()
      if (start - 24 * 60 * 60 * 1000 < time) {
        startDate = date.split(" ")[0]
        endDate = date.split(" ")[0]
      }
      this.feadTodayCache[isFeadTodayId] = { startDate, endDate }
      return { start: startDate, end: endDate }
    }
  }
})

export default useAdvertisingStore
