import { computed, defineComponent, onUnmounted, inject, h, ref, unref, watch } from "vue"
import { useUserStore, useCommonStore, useAppStore, PERSIST_KEY_PRE } from "@/store"
import { commonJS, pacvueToken } from "@pacvue/utils"
import common from "@pacvue/utils"
import { useRoute } from "vue-router"
import { PacvueMessageBox } from "@pacvue/element-plus"
import { dayjs } from "@pacvue/element-plus"
import { $t } from "@pacvue/frame/i18nEntry"
import { getCrossAuthCode } from "@/api/user"
import useAdvertisingStore from "@/views/Advertising/advertising-store"
import useFrameStore from "@pacvue/frame/frameStore"

// import router from '@/router'

export function usePermission() {
  const userStore = useUserStore()
  const userRole = computed(() => userStore.user?.userRole === "User")
  const adminRole = computed(() => userStore.user?.userRole === "Admin")
  const readRole = computed(() => userStore.user?.userRole === "Read")
  return {
    userRole,
    adminRole,
    readRole
  }
}

export function useUserInfo({ isRealTimeByPST = true } = {}) {
  const FrameStore = useFrameStore()
  const userStore = useUserStore()
  var commonStore = useCommonStore()
  var appStore = useAppStore()
  const route = useRoute()
  const userId = computed(() => userStore.user?.userId)
  const userName = computed(() => userStore.user?.userName)
  const clientId = computed(() => userStore.user?.clientId)
  const productLine = localStorage.getItem("productline") === "amazon" ? "kroger" : localStorage.getItem("productline")
  var dc = computed(() => userStore.user?.defaultCurrency)
  var userRole = computed(() => userStore.user?.userRole)
  var platform = computed(() => commonStore.platform || "amazon")
  var isRealTimeData = ref(true)
  var perference = computed(() => {
    return userStore.userSettings?.randA?.toUpperCase() || "ROAS"
  }) //获取偏好

  var chooseAccounts = computed(() => appStore.chooseAccounts)

  var startDate = computed(() => appStore.dateRange?.startDate)
  var endDate = computed(() => appStore.dateRange?.endDate)
  var startCompar = computed(() => {
    var prevStart = appStore.compareTime?.start
    if (productLine != "amazon" && prevStart) {
      return dayjs(prevStart, "MM/DD/YYYY").format("YYYY-MM-DD")
    }
    return prevStart
  })
  var endCompar = computed(() => {
    var prevEnd = appStore.compareTime?.end
    if (productLine != "amazon" && prevEnd) {
      return dayjs(prevEnd, "MM/DD/YYYY").format("YYYY-MM-DD")
    }
    return prevEnd
  })
  var dateFormat = computed(() => {
    var defaultDateFormat = (userStore.userSettings?.dateFormat || "") + ""
    if (defaultDateFormat == 0) {
      return "MM/DD/YYYY"
    }
    if (defaultDateFormat == 1) {
      return "DD/MM/YYYY"
    }
    return defaultDateFormat
  })
  var isExchange = computed(() => userStore.userSettings?.isExchange) //是否汇率装换
  //判断是否是今天
  var isFeadToday = computed(() => {
    var isToday = false
    var today = new Date()
    if (unref(isRealTimeByPST)) {
      var pstToday = dayjs().tz("PST8PDT").format("YYYY-MM-DD HH:mm:ss")
      today = dayjs(pstToday).toDate()
    }
    if (startDate.value == endDate.value && startDate.value == dayjs(today).format("MM/DD/YYYY")) {
      isToday = true
    }
    return isToday
  })
  var isRealTime = computed(() => {
    var realtimePage = ["Ad_Campaign", "Ad_Adgroup", "Ad_Targeting"]
    if (commonStore.platform == "walmart") {
      realtimePage = ["Campaign", "Ad_Campaign"]
    }
    const { path, meta, name } = route
    const menu = FrameStore.menuMap[path]
    return isFeadToday.value && realtimePage.includes(menu.alias)
  })
  //数据开启campare
  var isCompare = computed(() => appStore.isCompare && appStore.showDateCompare) //是否显示campare数据
  var isDataCampare = computed(() => isCompare.value && !appStore.compareSummary)
  var isSummaryCompare = computed(() => isCompare.value) //是否显示summary数据
  return {
    userId,
    userName,
    userRole,
    clientId,
    productLine,
    dc,
    platform,
    isRealTimeData,
    perference,
    chooseAccounts,
    startDate,
    endDate,
    startCompar,
    endCompar,
    isExchange,
    dateFormat,
    isRealTime,
    isFeadToday,
    isCompare,
    isDataCampare,
    isSummaryCompare
  }
}
export function PacvueSetDateRange({ startDate, endDate }) {
  var appStore = useAppStore()
  appStore.SET_DATERANGE({ startDate, endDate })
}
export function getMoneyCode(market) {
  var userInfo = useUserInfo()
  var dc = market || userInfo.dc.value
  return commonJS.marketCode(dc)
}
export function getDynamicMoneyCode(market) {
  var userInfo = useUserInfo()
  var moneyCode = computed(() => {
    var dc = market || userInfo.dc.value
    return commonJS.marketCode(dc)
  })
  return moneyCode
}
export function getQuery() {
  let $route = useRoute()
  let routeQuery = null
  if ($route.query.query) {
    routeQuery = JSON.parse(commonJS.decrypt($route.query.query))
  }
  return routeQuery
}
export function setQuery(query) {
  let routeQuery = commonJS.encrypt(JSON.stringify(query))
  return routeQuery
}
export function getProIndexCompareState(type, value) {
  var colorMap = {
    gray: " #b2b2b2",
    green: "#28c76f",
    orange: "#fbaf46",
    red: "#ea5455"
  }
  let formatType = type.toLowerCase()
  value = value + ""
  let formatValue = Number(value.replace("%", ""))
  var ascRedField = ["acospercent", "cpapercent", "cpcpercent", "cpc", "cpm", "acos", "tacos", "cpa"]
  var ascGrayField = ["spendpercent", "spend", "imp", "impression", "impressions", "totalspendpercent", "campaignspend", "adspend"]
  let orderBy = "right"
  let colorStatus = "gray"
  if (!isNaN(formatValue)) {
    formatValue = PacvueKeepDigit(Number(formatValue), 2)
    if (ascRedField.indexOf(formatType) != -1) {
      //ASC RED
      if (formatValue > 0) {
        colorStatus = "red"
        orderBy = "up"
        if (formatValue <= 50) {
          colorStatus = "orange"
        }
      } else if (formatValue < 0) {
        colorStatus = "green"
        orderBy = "down"
      } else {
        colorStatus = "gray"
      }
    } else if (ascGrayField.indexOf(formatType) != -1) {
      //ASC GRAY
      if (formatValue > 0) {
        orderBy = "up"
      } else if (formatValue < 0) {
        orderBy = "down"
      }
    } else {
      //ASC GREEN
      if (formatValue > 0) {
        colorStatus = "green"
        orderBy = "up"
      } else if (formatValue < 0) {
        colorStatus = "red"
        orderBy = "down"
        if (formatValue >= -50) {
          colorStatus = "orange"
        }
      } else {
        colorStatus = "gray"
      }
    }
  }
  return {
    dir: orderBy,
    color: colorStatus,
    colorVal: colorMap[colorStatus]
  }
}

/**
 * @function {将带有透明度的rga转换为rga}
 * @param {*} _color
 * @param {*} _opacity
 * @returns
 */
export function rgbWithOpacity(_color, _opacity) {
  _opacity = _opacity || 0
  return RgbaTohex(hexToRGBA(_color, _opacity))
}
/**
 * @function {将十六进制颜色转换为rgba}
 * @param {*} _color
 * @param {*} _opacity
 * @returns
 */
function hexToRGBA(_color, _opacity) {
  let sColor = _color.toLowerCase()
  // 十六进制颜色值的正则表达式
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = "#"
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    // 处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)))
    }
    return "rgba(" + sColorChange.join(",") + "," + _opacity + ")"
  }
  return sColor
}
/**
 * @function {将rgba转换十六进制颜色}
 * @param {*} color
 * @returns
 */
function RgbaTohex(color) {
  var values = color
    .replace(/rgba?\(/, "")
    .replace(/\)/, "")
    .replace(/[\s+]/g, "")
    .split(",")
  var a = parseFloat(values[3] || 1),
    r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
    g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
    b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255)
  return "#" + ("0" + r.toString(16)).slice(-2) + ("0" + g.toString(16)).slice(-2) + ("0" + b.toString(16)).slice(-2)
}
/**
 * @function 监控元素大小变化
 * @param {} param0 el, callback
 */
export function watchElResize({ el, callback } = {}) {
  var resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      var wrapWidth = entry.contentRect.width
      callback && callback(entry)
    }
  })
  resizeObserver.observe(el)
  return resizeObserver
}
export function formatTimeZone(value) {
  var userInfo = useUserInfo()
  value = value || userInfo.dc.value
  if (value == "America/Los_Angeles" || value == "US") {
    return "PST"
  } else if (value == "MST") {
    return "MST"
  } else if (value == "America/Eastern" || value == "CA") {
    return "EST"
  } else if (value == "America/Central") {
    return "CST"
  } else if (value == "Europe/Paris" || value == "SE" || value == "DE" || value == "FR" || value == "NL" || value == "UK" || value == "IT") {
    return "CET"
  } else if (value == "Europe/London" || value == "ES") {
    return "GMT"
  } else if (value == "Asia/Dubai") {
    return "GST"
  } else if (value == "China/Beijing") {
    return "CST"
  } else if (value == "Asia/Tokyo" || value == "JP") {
    return "JST"
  } else if (value == "Australia/Sydney" || value == "AU") {
    return "AEST"
  } else if (value == "Asia/Kolkata" || value == "IN") {
    return "IST"
  } else if (value == "SA") {
    return "AST"
  } else if (value == "AE") {
    return "GST"
  } else if (value == "EG") {
    return "EST"
  } else if (value == "PL") {
    return "CEST"
  } else if (value == "TR") {
    return "GTB"
  } else if (value == "BR") {
    return "BRT"
  } else if (value == "MX") {
    return "CDT"
  } else if (value == "Europe/Amsterdam") {
    return "CEST"
  } else {
    return ""
  }
}

/**
 * @function 开启通信
 * @returns
 */
export function startLongCommunication(callBack) {
  let connection = null
  const initConnection = () => {
    const { getTokens } = pacvueToken
    let suffix = "com"
    if (window.location.hostname.indexOf(".cn") != -1) {
      suffix = "cn"
    }
    let prefix = ""
    if (window.location.hostname.indexOf("eu") != -1) {
      prefix = "-eu"
    }
    const url = "https://messageserver" + prefix + ".pacvue." + suffix + "/messageserver/messageHub"
    connection = new window.signalR.HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: async function () {
          let token = getTokens()
          return token
        }
        //, transport: signalR.HttpTransportType.LongPolling
      })
      .configureLogging(window.signalR.LogLevel.Trace)
      .build()

    //.withAutomaticReconnect()
    connection.on("ReceiveMessage", function (message) {
      let json = {}
      try {
        json = JSON.parse(message)
      } catch (ex) {
        json = message
      }
      if (Object.prototype.toString.call(callBack) == "[object Function]") {
        callBack(json)
      }
    })

    connection
      .start()
      .then(function () {
        console.log(">>>>>>>>>start communication")
      })
      .catch(function (err) {
        // return console.error(err.toString())
      })

    // re-establish the connection if connection dropped
    connection.onclose(function () {
      if (connection.closeType != "custom") {
        setTimeout(connection.start(), 5000)
      }
    })
  }
  if (!window.signalR) {
    // 生成请求 signalR 的 script 标签
    const scriptEl = document.createElement("script")
    scriptEl.async = true
    scriptEl.src = "/js/websocket/signalr.min.js"
    scriptEl.type = "text/javascript"
    scriptEl.onload = () => initConnection()
    document.body.appendChild(scriptEl)
  } else {
    initConnection()
  }
  return connection
}

export function goUrl({ path, query, storeType = "url", queryName = "query" }) {
  if (window.routerObj) {
    path = window.routerObj.resolve({ path }).href || ""
  } else {
    path = path || ""
  }
  window.open(path + (query === undefined ? "" : "?" + queryName + "=" + setQuery(query)))
}

export function createMoreActionItem({ label, value, icon, type, className, tip, dataCy, size } = {}) {
  value = value || label.replace(/\s/g, "")
  return {
    label: label,
    value: value,
    type: type || "svg-font", //svg-font svg-icon default
    icon: icon,
    tip: tip || label,
    size,
    dataCy,
    className,
    onClick: function ({ rowData, column }) {}
  }
}
// orderedUnits: 'Ordered Units',
// totalRevenue: 'Total Revenue',
// y轴文本格式化方法

export const yLabelFormatter = (type, val) => {
  const userStore = useUserStore()
  if (type === "OrderPrice" || type === "CPC" || type === "CPA" || type === "T-ROAS") {
    return common.formatMoney1(val, common.marketCode(userStore.user?.defaultCurrency))
  } else if (
    type === "shippedCogs" ||
    type === "Total Revenue" ||
    type === "Total Ad Sales" ||
    type === "Sales" ||
    type === "NewToBrandSales" ||
    type === "Ad Sales" ||
    type === "Organic Revenue" ||
    type === "Total Sales" ||
    type === "Organic Sales" ||
    type === "Total Media Sales"
  ) {
    return common.formatSalesMoney(val, common.marketCode(userStore.user?.defaultCurrency))
  } else if (
    type === "Impression" ||
    type === "SaleUnits" ||
    type === "Total Sale Units" ||
    type === "Click" ||
    type === "NewToBrandOrders" ||
    type === "Conversion" ||
    type === "NewToBrandUnitsOrdered" ||
    type === "Ordered Units"
  ) {
    return common.toThousandsx(val)
  } else if (
    type === "ACOS" ||
    type === "CTR" ||
    type === "CVR" ||
    type === "NewToBrandOrderRate" ||
    type == "NewToBrandOrdersPercentage" ||
    type == "NewToBrandSalesPercentage" ||
    type == "NewToBrandUnitsOrderedPercentage" ||
    type == "T-ACOS" ||
    type === "netPpm"
  ) {
    return common.formatPercent(val)
  } else if (type === "ROAS" || type === "T-ROAS") {
    return common.formatMoneyRoas(val, common.marketCode(userStore.user?.defaultCurrency))
  } else if (type === "Spend" || type === "Ad Spend") {
    return common.formatSpendsMoney(val, common.marketCode(userStore.user?.defaultCurrency))
  } else {
    return val
  }
}

export function getWeekStartSun(d, market, dateFormat) {
  var marketCode = market || "US"
  // var time = dayjs(d, dateFormat||marketDateFormat(marketCode));
  // return time.week();
  var time = dayjs(d, dateFormat || commonJS.marketDateFormat(marketCode))
  var FirstOfTime = time.startOf("week")
  var FirstDayOfYear = dayjs(FirstOfTime)
  FirstDayOfYear = FirstDayOfYear.date(4) //4号作为分界点
  FirstDayOfYear = FirstDayOfYear.month(0)
  var interval = 0
  var FirstDayOfWeek = FirstDayOfYear.startOf("week") //第一周的第一天日期
  var days = FirstOfTime.diff(FirstDayOfWeek, "days")
  days += FirstDayOfYear.day()
  let num = Math.floor(days / 7) + 1 - interval
  return num
}
/**
 * @function 获取path信息
 * @returns
 */
export function PacvueGetPathInfo({ pathname = "" } = {}) {
  pathname = pathname || window.location.pathname
  var pathnameList = pathname.split("/").slice(1)
  var pathnameSize = pathnameList.length
  var firstMenu = pathnameList[0] || ""
  var lastMenu = pathnameList[pathnameSize - 1] || ""
  return {
    firstMenu: firstMenu,
    lastMenu: lastMenu,
    firstMenuLower: firstMenu.toLowerCase(),
    lastMenuLower: lastMenu.toLowerCase(),
    pathname: pathname,
    pathnameLower: pathname.toLowerCase()
  }
}
export function equalJSON(obj1, obj2) {
  var isEqual = true
  var obj1Type = Object.prototype.toString.call(obj1)
  var obj2Type = Object.prototype.toString.call(obj2)
  var normalTyps = ["[object String]", "[object Number]", "[object Undefined]", "[object Null]"]
  if (normalTyps.includes(obj1Type) || normalTyps.includes(obj2Type)) {
    return obj1 == obj2
  }
  if (obj1Type != obj2Type) {
    return false
  } else if (obj1Type == "[object Array]") {
    if (obj1.length != obj2.length) {
      return false
    }
  } else if (obj1Type == "[object Object]") {
    var obj1Names = Object.keys(obj1)
    var obj2Names = Object.keys(obj2)
    if (obj1Names.length != obj2Names.length) {
      return false
    }
  }
  if (Object.keys(obj2).length > Object.keys(obj1).length) {
    ;[obj1, obj2] = [obj2, obj1]
  }
  for (var key in obj1) {
    if (obj2.hasOwnProperty(key)) {
      var value1 = obj1[key]
      var value2 = obj2[key]
      if (value1 != value2) {
        var value1Type = Object.prototype.toString.call(value1)
        var value2Type = Object.prototype.toString.call(value2)
        if (value1Type != value2Type) {
          //类型不一样
          isEqual = false
          break
        } else if (value1Type == "[object Array]") {
          if (value1.length != value2.length) {
            isEqual = false
            break
          }
          if (!equalJSON(value1, value2)) {
            isEqual = false
            break
          }
        } else if (value1Type == "[object Object]") {
          if (!equalJSON(value1, value2)) {
            isEqual = false
            break
          }
        } else {
          isEqual = false
          break
        }
      }
    } else {
      isEqual = false
      break
    }
  }
  return isEqual
}
/**
 * @function {保留几位小数}
 * @param {保留小数的数字} value
 * @param {保留小数的位数} digitCount
 */
export function PacvueKeepDigit(value, digitCount, isIntercept) {
  if (String(value).trim() === "") return ""
  var numVal = Number(value)
  digitCount = digitCount || 0
  if (isNaN(numVal)) {
    return 0
  }
  var divisor = Math.pow(10, digitCount)
  if (isIntercept) {
    return Math.floor(numVal + "e" + digitCount) / divisor
    return Math.floor(numVal * divisor) / divisor
  } else {
    return Math.round(numVal + "e" + digitCount) / divisor
    return Math.round(numVal * divisor) / divisor
  }
}
/**
 * @function {} 设置临时变量参数
 * @returns
 */
export function usePageTempParamsStore() {
  var userInfo = useUserInfo()
  var userId = userInfo.userId.value
  var platform = userInfo.platform.value
  var prev = platform + "_" + userId + "_"
  var setToPageTempParams = function (val) {
    sessionStorage.setItem(prev + "pageTemp", JSON.stringify(val))
  }
  var getToPageTempParams = function () {
    var val = sessionStorage.getItem(prev + "pageTemp")
    return JSON.parse(val) || null
  }
  var deleteToPageTempParams = function () {
    sessionStorage.removeItem(prev + "pageTemp")
  }
  return {
    setToPageTempParams,
    getToPageTempParams,
    deleteToPageTempParams
  }
}
export function useLocalStore() {
  var userInfo = useUserInfo()
  var userId = userInfo.userId.value
  var platform = userInfo.platform.value
  var prev = platform + "_" + userId + "_"
  var setLocalStore = (key, val) => {
    localStorage.setItem(prev + key, JSON.stringify(val))
  }
  var getLocalStore = (key) => {
    try {
      var val = localStorage.getItem(prev + key)
      return JSON.parse(val) || null
    } catch (ex) {
      return null
    }
  }
  var delLocalStore = (key) => {
    localStorage.removeItem(prev + key)
  }
  return {
    setLocalStore,
    getLocalStore,
    delLocalStore
  }
}
export function PacvueHasPermission() {
  var userInfo = useUserInfo()
  var userRole = userInfo.userRole.value
  if (userRole == "Read") {
    PacvueMessageBox({
      title: "Tip",
      message: $t("key1341"),
      type: "warning"
    })
    return false
  }
  return true
}
export function useFormatInput({ callback } = {}) {
  var formatInt = (val, fieldKey) => {
    var realVal = val
    if (val) {
      realVal = parseInt(val)
    }
    callback && callback({ key: fieldKey, value: realVal })
    return realVal
  }
  return { formatInt }
}
export function usePacvueLocalStore() {
  var userInfo = useUserInfo()
  return {
    setTagging: function (val, tagname = "") {
      var userId = userInfo.userId.value
      var prev = userId + "_"
      localStorage.setItem(prev + "pacvue_" + tagname + "tagging", JSON.stringify(val))
    },
    sethassubTagging: function (index, val) {
      var userId = userInfo.userId.value
      var prev = userId + "_"
      var subtagArr = ["adgroup", "campaign", "asin"]
      localStorage.setItem(prev + "pacvue_hassub" + subtagArr[index] + "tagging", JSON.stringify(val))
    },
    gethassubTagging: function (tagname = "") {
      var userId = userInfo.userId.value
      var prev = userId + "_"
      var adgroupsubtaggingStr = localStorage.getItem(prev + "pacvue_hassub" + tagname + "tagging")
      var subtagggingAdgroupSelectedArray = {}
      if (adgroupsubtaggingStr) {
        subtagggingAdgroupSelectedArray = JSON.parse(adgroupsubtaggingStr) || {}
      }
      return subtagggingAdgroupSelectedArray
    },
    setItem(key, value) {
      var userId = userInfo.userId.value
      var prev = userId + "_"
      localStorage.setItem(prev + "pacvue_" + key, JSON.stringify(value))
    },
    delItem(key) {
      var userId = userInfo.userId.value
      var prev = userId + "_"
      var uniqueId = prev + "pacvue_" + key
      localStorage.removeItem(uniqueId)
    },
    getItem(key) {
      var userId = userInfo.userId.value
      var prev = userId + "_"
      var uniqueId = prev + "pacvue_" + key
      var realVal = []
      var valStr = localStorage.getItem(uniqueId)
      if (valStr) {
        realVal = JSON.parse(valStr) || []
      }
      return realVal
    }
  }
}
export function strCapitalize(str) {
  str = str ? str : ""
  if (Object.prototype.toString.call(str) == "[object String]") {
    str = str.charAt(0).toUpperCase() + str.slice(1)
  }
  return str
}
export function usePacvueCustomBread({ name = "", value } = {}) {
  const BreadTitle = defineComponent({
    render: function () {
      return h("div", {
        class: ["font-bold", "text-[#45464f]"],
        style: { fontSize: "20px", "white-space": "nowrap", overflow: "hidden", "text-overflow": "ellipsis" },
        innerHTML: `${name ? name + ":" : ""}${value}`
      })
    }
  })
  const { updateBreadcrumbLeft } = inject("breadcrumb")
  updateBreadcrumbLeft(BreadTitle)
  onUnmounted(() => updateBreadcrumbLeft(null))
}
export function PacvueGetHost() {
  var protocol = location.protocol
  var host = location.host
  return protocol + "//" + host
}
export function jumpToRetailDashboard(appname, options, self) {
  var referrer = PacvueGetHost()
  var ApiName = appname || "retailer-test"
  var mode = import.meta.env.MODE || "development"
  if (appname == "retailer-test" && mode.includes("pro")) {
    ApiName = "retailer"
  }
  var hostname = window.location.hostname.toLowerCase()
  options = options || {}
  var redirect = options.redirect
  var tagId = options.tagId
  var parentTagId = options.parentTagId
  var extraStr = `${redirect ? "&redirect=" + redirect : ""}${tagId ? "&tagId=" + tagId : ""}${parentTagId ? "&parentTagId=" + parentTagId : ""}`
  var params = common.encrypt(JSON.stringify({ redirect: redirect, tagId: tagId, parentTagId: parentTagId }))
  var enviroment = getPacvueEnvironment()
  getCrossAuthCode().then((data) => {
    // common.encrypt
    if (hostname == "localhost") {
      window.open(`http://localhost:3001?authcode=${data}&query=${localStorage.getItem("productline")}&params=${params}&referrer=${referrer}${extraStr}`, self)
    } else if (hostname.lastIndexOf("dspwinner") != -1) {
      ApiName = ApiName == "product" ? "app" : ApiName
      window.open(`https://${ApiName}.dspwinner.${domain}?authcode=${data}&query=${localStorage.getItem("productline")}&params=${params}&referrer=${referrer}${extraStr}`, self)
    } else {
      var domain = window.location.href.indexOf("cn") != -1 ? "cn" : "com"
      // window.open(`http://localhost:3001?authcode=${data}&query=${common.encrypt(localStorage.getItem('productline'))}`)
      window.open(`https://${ApiName}.pacvue.${domain}?authcode=${data}&query=${localStorage.getItem("productline")}&params=${params}&referrer=${referrer}${extraStr}`, self)
      return
    }
    /*if (hostname == 'localhost') {
      window.open(`http://localhost:8080?authcode=${data}&referrer=${referrer}${extraStr}`)
    } else*/
    // if (enviroment == 'test') {
    //   window.open('/ShareCenter/index')
    //   // window.open(`https://${ApiName}test.pacvue.${domain}?authcode=${data}&referrer=${referrer}${extraStr}`)
    // } else {
    //   window.open(`https://${ApiName}.pacvue.${domain}?authcode=${data}&referrer=${referrer}${extraStr}`)
    // }
  })
}
function getPacvueEnvironment() {
  var hostname = window.location.hostname.toLowerCase()
  if (hostname.indexOf("dev") != -1 || hostname.indexOf("test") != -1 || hostname == "localhost") {
    return "test"
  } else if (hostname.indexOf("demo") != -1) {
    return "demo"
  } else {
    return "pro"
  }
}
export function copyJSON(str) {
  var a = JSON.stringify(str)
  var b = JSON.parse(a)
  return b
}
export async function PacvueGetMaxDateTime({ query }) {
  var advertisingStore = useAdvertisingStore()
  var userInfo = useUserInfo()
  var isFeadToday = userInfo.isFeadToday.value
  var profileIds = query.profileIds || []
  var result = { start: query.start, end: query.end }
  if (isFeadToday && result.start && result.end) {
    result = await advertisingStore.PacvueGetMaxDateTime(query)
  }
  return result
}
/**
 * 下载报表需要token验证
 *
 * @param {*} url
 * @param {*} params
 */
export function formSubmitWithToken(url, params, method = "post", dataType, filterSelf = false, deep, callback) {
  params = params || {}
  var filter = params["filter"]
  if (Object.prototype.toString.call(filter) == "[object Array]") {
    if (!filterSelf) {
      var filterJson = JSON.stringify(filter)
      delete params["filter"]
      params.filterJson = filterJson
    }
  }
  var aParams = [] // 用来存储“名-值”对的数组
  var formParams = ""
  if (dataType == "form-params") {
    formParams = $.param(params)
  } else if (dataType != "json") {
    for (var key in params) {
      var value = params[key]
      if (deep) {
        formItemRecursion(value, key, aParams, "push-qs")
        continue
      }
      if (Object.prototype.toString.call(value) == "[object Array]") {
        value.forEach((x, i) => {
          var nkey = encodeURIComponent(key)
          nkey += `[${i}]=`
          nkey += encodeURIComponent(x)
          aParams.push(nkey)
        })
      } else if (Object.prototype.toString.call(value) == "[object Object]") {
        for (var childKey in value) {
          var value2 = value[childKey]
          if (Object.prototype.toString.call(value2) == "[object Array]") {
            value2.forEach((x, i) => {
              var nkey2 = encodeURIComponent(key)
              nkey2 += `[${childKey}][${i}]=`
              nkey2 += encodeURIComponent(x)
              aParams.push(nkey2)
            })
          } else if (Object.prototype.toString.call(value2) == "[object Object]") {
            for (var childCell in value2) {
              var objkey2 = encodeURIComponent(key)
              objkey2 += `[${childKey}][${childCell}]=`
              objkey2 += encodeURIComponent(value2[childCell])
              aParams.push(objkey2)
            }
          } else if (Object.prototype.toString.call(value2) == "[object String]") {
            var objkey = encodeURIComponent(key)
            objkey += `[${childKey}]=`
            objkey += encodeURIComponent(value2)
            aParams.push(objkey)
          }
        }
      } else if (value === undefined) {
      } else {
        var sParam = encodeURIComponent(key)
        sParam += "="
        sParam += encodeURIComponent(params[key])
        aParams.push(sParam)
      }
    }
    formParams = aParams.join("&")
  } else {
    formParams = JSON.stringify(params || {})
  }

  // 原生请求
  var xhr = new XMLHttpRequest()
  var methods = "post"
  if (method == "notoken") {
    methods = "post"
  } else {
    methods = method
  }
  xhr.open(methods, url, true) //get请求，请求地址，是否异步
  if (dataType != "json") {
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
  } else {
    xhr.setRequestHeader("Content-type", "application/json")
  }
  if (method == "notoken") {
  } else {
    let { getTokens } = pacvueToken
    let token = "Bearer " + getTokens()
    xhr.setRequestHeader("Authorization", token)
  }
  xhr.responseType = "blob"
  xhr.onload = function (evt) {
    if (this.status == 200) {
      var data = this.response

      var blob = new Blob([data])
      var a = document.createElement("a")
      var fileurl = window.URL.createObjectURL(blob)
      a.href = fileurl
      //设置文件名称
      var fileName = xhr.getResponseHeader("content-disposition").split(";")[1].split("=")[1] || ""
      fileName = fileName.replace(/\"/g, "")
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(fileurl)

      if (callback && Object.prototype.toString.call(callback) === "[object Function]") {
        callback()
      }
    }
  }
  xhr.send(formParams)
}
export function hasMenuPermissions({ menuName, pathname }) {
  const FrameStore = useFrameStore()
  if (pathname) {
    return FrameStore.menuMap[pathname] ? true : false
  }
  var menuList = FrameStore.menu || []
  if (menuName) {
    var isExist = recuisionMenu({ menuList, menuName })
    return isExist
  }
  return false
}
function recuisionMenu({ menuList, menuName }) {
  for (var i = 0, menuSize = menuList.length; i < menuSize; i++) {
    var menuItem = menuList[i]
    var children = menuItem.children || []
    if (children.length) {
      var isExist = recuisionMenu({ menuList: children, menuName })
      if (isExist) {
        return true
      }
    } else if (menuItem.menuName == menuName) {
      return true
    }
  }
  return false
}
export function PacvueFormatDate(time, type) {
  if (!time) return "--"
  var numReg = /^\d+(.\d+)?$/
  if (numReg.test(time)) {
    time = parseFloat(time)
  }
  let dateStr = ""
  switch (type) {
    case "date":
      dateStr = dayjs(time).format("MM/DD/YYYY")
      break
    case "minute":
      dateStr = dayjs(time).format("MM/DD/YYYY HH:mm")
      break
    case "second":
      dateStr = dayjs(time).format("MM/DD/YYYY HH:mm:ss")
      break
  }
  return dateStr
}
export function PacvueWalmartJudgeState(row, submenu, type) {
  let state = row.status || row.keywordState || row.itemState || row.state
  if (state && submenu != "Item") {
    state = state.toString()
    state = state.toLowerCase()
    if (state == "completed" || state == "deleted" || state == "archived" || state == "false" || state == "ended") {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}
export function PacvueWithPermission(menuAlias = "Advertising") {
  if (!window.permissionObj) {
    return "edit"
  }
  var permissionType = window.permissionObj[menuAlias]
  //0是没有权限,1的状态为显示不可编辑,//2就是有所有权限

  if (permissionType == 0) {
    return "remove"
  } else if (permissionType == 1) {
    return "disabled"
  }
  return "edit"
}
export function PacvueDownload({ fetch, query = {}, fileName, isAsync = true, suffix = "xlsx" }) {
  fetch(query).then((res) => {
    if (!isAsync) {
      const today = dayjs().format("YYYY-MM-DD")
      commonJS.DownLoad(res, fileName + `${today}` + "." + suffix)
    }
  })
}
export function PacvueTagListFormat(data) {
  data = data || []
  var dataList = data.filter((dataItem) => {
    if (dataItem.isEditable) {
      var subTags = dataItem.children || dataItem.subTags || []
      if (subTags?.length > 0) {
        var subTagList = dataItem.subTags.filter((val) => val.isEditable)
        if (subTagList.length) {
          dataItem.subTags = subTagList
          return dataItem
        } else {
          return false
        }
      }
      return dataItem
    }
  })
  return dataList
}
export function PacvueRequestTagFormat({ requestTag, params = {} }) {
  if (requestTag) {
    if (!params.isEditable) {
      return requestTag
    }
    return new Promise((resolve, reject) => {
      requestTag.then((data) => {
        var dataList = PacvueTagListFormat(data)
        resolve(dataList)
      })
    })
  }
}
/**
 *
 * @param {需要驼峰化的字符串} str String
 * @param {分隔符} split String
 * @returns String
 */
export function PacvueStrHump(str, split = "-") {
  str = str ? str : ""
  if (Object.prototype.toString.call(str) == "[object String]") {
    var strList = str.split(split)
    var capStrList = strList.map((item) => {
      return strCapitalize(item)
    })
    return capStrList.join("")
  }
  return str
}
/**
 * function {数组多属性排序}
 * @param {排序的数据} array
 * @param {属性对象,Array<{name:'排序字段',sort:排序方法，orderDir:'排序方向（asc,desc）'}>} props
 * @param {*} sortDir
 * @returns
 */
export function PacvueArrayMultiPropSort(array, props) {
  var getSortDir = function (val1, val2, sortFn, sortDir) {
    if (Object.prototype.toString.call(sortFn) == "[object Function]") {
      return sortFn(val1, val2)
    }
    if (val1 > val2) {
      return sortDir != "desc" ? 1 : -1
    } else if (val1 < val2) {
      return sortDir != "desc" ? -1 : 1
    } else {
      return 0
    }
  }
  var propsSize = props.length
  var filterArray = array.sort(function (a, b) {
    for (var i = 0; i < propsSize; i++) {
      var prop = props[i].name
      var sortFn = props[i].sort //排序方式
      var sortDir = props[i].orderDir //排序方向asc ,desc
      var val1 = a[prop]
      var val2 = b[prop]
      var result = getSortDir(val1, val2, sortFn, sortDir)
      if (result != 0) {
        return result
      }
    }
    return 0
  })
  return filterArray
}
export function PacvueGetDateRangeByTime({ dim, date, minDate, maxDate, dateFormat = "YYYY-MM-DD" } = {}) {
  var dateTime = parseFloat(date)
  var dateStr = commonJS.parseDate(dateTime, dateFormat, null, null, true, false)
  var dimType = "day"
  switch (dim) {
    case "Week":
    case "week":
    case "weekOfYear":
      dimType = "week"
      break
    case "Day":
    case "day":
    case "date":
      break
    case "Month":
    case "month":
    case "monthOfYear":
      dimType = "month"
      break
    case "Quarter":
    case "quarter":
    case "quarterOfYear":
      dimType = "quarter"
      break
    case "Year":
    case "year":
      dimType = "year"
      break
  }
  var startDateM = dayjs(dateStr, dateFormat).startOf(dimType)
  var endDateM = dayjs(dateStr, dateFormat).endOf(dimType)
  var minDateM = dayjs(minDate, dateFormat)
  var maxDateM = dayjs(maxDate, dateFormat)
  if (startDateM.isBefore(minDateM)) {
    startDateM = minDateM
  }
  if (maxDateM.isBefore(endDateM)) {
    endDateM = maxDateM
  }
  var startDate = startDateM.format("YYYY-MM-DD")
  var endDate = endDateM.format("YYYY-MM-DD")
  return {
    startDate,
    endDate
  }
}

export function PacvueCreateFilterStoreKey({ storeKey } = {}) {
  var userInfo = useUserInfo()
  var userId = userInfo.userId.value
  var prev = PERSIST_KEY_PRE + userId + "_"
  return prev + storeKey
}
export function PacvueGetSupportInfo() {
  var supportInfo = {}
  try {
    supportInfo = JSON.parse(localStorage.walmart_Advertising_supportInfo) ?? {}
  } catch (ex) {
    var advertisingStore = useAdvertisingStore()
    supportInfo = advertisingStore?.supportInfo ?? {}
  }
  return supportInfo
}
export function ParseUrlParams(url) {
  url = url ?? location.href
  var obj = {}
  var keyvalue = []
  var key = "",
    value = ""
  var paraString = url.substring(url.indexOf("?") + 1, url.length).split("&")
  for (var i in paraString) {
    keyvalue = paraString[i].split("=")
    key = keyvalue[0]
    value = decodeURIComponent(keyvalue[1])
    obj[key] = value
  }
  return obj
}

export function usePacvueAsyncDialog({ defaultDialogMode = "Edit" } = {}) {
  var dialogMode = ref(defaultDialogMode)
  var dialogRef = ref(null) //弹框的ref
  var watchDialogRef = ({ callback, mode } = {}) => {
    console.log(oldDialogMode, mode)
    var oldDialogMode = dialogMode.value
    if (oldDialogMode == mode) {
      //业务处理逻辑
      callback && callback()
    } else {
      dialogMode.value = mode
      var unwatch = watch(dialogRef, (diaRef, oldDiaRef) => {
        if (diaRef && oldDiaRef === null) {
          //业务处理逻辑
          callback && callback()
          //取消监控
          unwatch()
          unwatch = null
        }
      })
    }
  }
  return {
    dialogMode,
    dialogRef,
    watchDialogRef
  }
}
