import request from "@pacvue/frame/request"
import { project_rule_api, project_rule_meta_api, project_rule_sov_api, project_rule_report_api, project_rule_engine_api } from "./index"
const NODE_ENV = import.meta.env.VUE_APP_ENV

const dataurl = project_rule_api
const metaUrl = project_rule_meta_api
const reportUrl = project_rule_report_api
const ruleEngineUrl = project_rule_engine_api
const sovApi = project_rule_sov_api

// 创建rule
export function createRule(data = {}) {
  return request({
    url: `${dataurl}/definition`,
    method: "POST",
    data: data
  })
}

//ruleInfo
export function getRuleInfo(params) {
  return request({
    url: `${dataurl}/definition/${params.ruleId}`,
    method: "get"
  })
}

//编辑rule
export function editRule(data = {}) {
  return request({
    url: `${dataurl}/definition/editRule`,
    method: "POST",
    data: data
  })
}

//check ruleName
export function checkRuleName(data) {
  return request({
    url: `${dataurl}/definition/checkRuleName`,
    method: "POST",
    data: data
  })
}

//requirement options
export function getRequirementMetrics(params) {
  return request({
    url: `${dataurl}/config/requirementMetrics`,
    method: "get",
    params
  })
}

// action options
export function getActionMetrics(params) {
  return request({
    url: `${dataurl}/config/actionMetrics`,
    method: "get",
    params
  })
}

//rule type
export function getRuleTypes(params) {
  return request({
    url: `${dataurl}/config/ruleTypes`,
    method: "get",
    params
  })
}

//supported timeZones
export function getSupportedTimeZones() {
  return request({
    url: `${dataurl}/config/supportedTimeZones`,
    method: "get"
  })
}

//getAdGroupStatus
export function getAdGroupStatus() {
  return request({
    url: `${metaUrl}/filter/adGroupStatus`,
    method: "GET"
  })
}

//apply adGroup
export function getAdGroups(data = {}) {
  return request({
    url: `${metaUrl}/apply/adGroups`,
    method: "POST",
    data: data
  })
}

//apply adGroupTags
export function getAdGroupTags(data = {}) {
  return request({
    url: `${metaUrl}/apply/adGroupTags`,
    method: "POST",
    data: data
  })
}

//apply adGroup
export function getCampaignTags(data = {}) {
  return request({
    url: `${metaUrl}/apply/campaignTags`,
    method: "POST",
    data: data
  })
}

//apply adGroup
export function getCampaigns(data = {}) {
  return request({
    url: `${metaUrl}/apply/campaigns`,
    method: "POST",
    data: data
  })
}

//apply portfolios
export function getPortfolios(data = {}) {
  return request({
    url: `${metaUrl}/apply/portfolios`,
    method: "POST",
    data: data
  })
}

//getLineItems
export function getLineItems(data = {}) {
  return request({
    url: `${metaUrl}/apply/lineItems`,
    method: "POST",
    data: data
  })
}

//getProductTags
export function getProductTags(data = {}) {
  return request({
    url: `${metaUrl}/apply/productTags`,
    method: "POST",
    data: data
  })
}

//getprofiles 通用 列表页 filter
export function getProfilesForFilter(data) {
  return request({
    url: `${dataurl}/definition/getProfile`,
    method: "post",
    data
  })
}

//getprofiles 通用（非ic）
export function getProfiles(data) {
  return request({
    url: `${metaUrl}/filter/profiles`,
    method: "post",
    data
  })
}
//profile-IC
export function getICProfileOption() {
  return request({
    url: `${metaUrl}/filter/profiles`,
    method: "get"
  })
}

//campaignStatus
export function getCampaignStatus() {
  return request({
    url: `${metaUrl}/filter/campaignStatus`,
    method: "GET"
  })
}

///filter/lineItemStatus
export function getLineItemStatus() {
  return request({
    url: `${metaUrl}/filter/lineItemStatus`,
    method: "GET"
  })
}

export function getCampaignTypes() {
  return request({
    url: `${metaUrl}/filter/campaignTypes`,
    method: "GET"
  })
}
//keywordTags
export function getKeywordTags(data = {}) {
  return request({
    url: `${metaUrl}/apply/keywordTags`,
    method: "POST",
    data: data
  })
}
//itemTags
export function getItemTags(data = {}) {
  return request({
    url: `${metaUrl}/filter/itemTags`,
    method: "GET"
  })
}

export function getASINTags(data = {}) {
  return request({
    url: `${metaUrl}/apply/asinTags`,
    method: "POST",
    data: data
  })
}

export function getNegativeKeywordList(data = {}) {
  return request({
    url: `${metaUrl}/apply/negativeKeywordList`,
    method: "POST",
    data: data
  })
}

export function getNegativePatList(data = {}) {
  return request({
    url: `${metaUrl}/apply/negativePatList`,
    method: "POST",
    data: data
  })
}

// adGroupsMapping
export function getAdGroupsMapping(data = []) {
  return request({
    url: `${metaUrl}/apply/adGroups/mapping`,
    method: "POST",
    data: data
  })
}

// getAdGroupsName
export function getAdGroupsName(data = []) {
  return request({
    url: `${metaUrl}/apply/adGroups/name`,
    method: "POST",
    data: data
  })
}

// getMappingLabels
export function getMappingLabels(data = []) {
  return request({
    url: `${metaUrl}/apply/mappingLabels`,
    method: "GET"
  })
}

// negative rule
export function findProfileByAdvertiserIds(data = []) {
  return request({
    url: `${metaUrl}/apply/findProfileByAdvertiserIds`,
    method: "POST",
    data: data
  })
}

export function findCampaignByProfileIds(data = []) {
  return request({
    url: `${metaUrl}/apply/findCampaignByProfileIds`,
    method: "POST",
    data: data
  })
}

export function findAdGroupByCampaignIdAndProfileId(data = []) {
  return request({
    url: `${metaUrl}/apply/findAdGroupByCampaignIdAndProfileId`,
    method: "POST",
    data: data
  })
}

export function getSovCategorys(data = []) {
  return request({
    url: `${metaUrl}/apply/sovCategorys`,
    method: "POST",
    data: data
  })
}

export function getSovCategorysWalmart() {
  return request({
    url: "https://apiwalmart.pacvue.com/api/Analysis/userBrands",
    method: "GET"
  })
}

export function getSovKeywordTags(data = []) {
  return request({
    url: `${metaUrl}/apply/sovKeywordTags`,
    method: "POST",
    data: data
  })
}

export function getAsinInsight(data = []) {
  return request({
    url: `${sovApi}/SOV/getAsinInsight`,
    method: "POST",
    data: data
  })
}

export function getMyBrandList(data) {
  return request({
    url: `${sovApi}/SOV/getMyBrands`,
    method: "post",
    data
  })
}

export function getRuleKeyword(data = []) {
  return request({
    url: `${sovApi}/SOV/GetRuleKeyword`,
    method: "POST",
    data: data
  })
}

export function getSovAsins(data = []) {
  return request({
    url: `${sovApi}/Rule/GetSovRuleAsins`,
    method: "POST",
    data: data
  })
}

export function getSovAsinsWalmart(data = []) {
  return request({
    url: `${sovApi}/rule/sovBidItems`,
    method: "POST",
    data: data
  })
}

export function getAsinTags(data = []) {
  return request({
    url: `${metaUrl}/apply/asinTags`,
    method: "POST",
    data: data
  })
}

export function getCampaignNames(data = []) {
  return request({
    url: `${metaUrl}/entity/getCampaignNames`,
    method: "POST",
    data: data
  })
}

export function getCommercePermission() {
  return request({
    url: `${dataurl}/config/getCommercePermission`,
    method: "GET"
  })
}

export function getHave3pProfile() {
  return request({
    url: `${metaUrl}/apply/have3pProfile`,
    method: "GET"
  })
}

export function hasClickHitMode() {
  return request({
    url: `${dataurl}/definition/hasClickHitMode`,
    method: "GET"
  })
}

export function getVendorGroupIds(data) {
  return request({
    url: `${reportUrl}/commerce/user/getVendorGroupIds`,
    method: "POST",
    data
  })
}

export function getProfiteroCategory(clientId, timeZone) {
  return request({
    url: `${reportUrl}/profitero/category?clientId=${clientId}&timeZone=${timeZone}`,
    method: "POST"
    // data,
  })
}

export function handleDailyInventory(data) {
  return request({
    url: `${ruleEngineUrl}/user/handleDailyInventory`,
    method: "POST",
    data
  })
}

export function getHave1p3pProfile() {
  return request({
    url: `${metaUrl}/apply/have1p3pProfile`,
    method: "GET"
  })
}
