import useAdvertisingStore from "./views/Advertising/advertising-store"
import { useAppStore, useUserStore, useCommonStore } from "@/store"

export default {
  routerInterceptors: async function (to, from, next) {
    const userStore = useUserStore()
    const appStore = useAppStore()
    const commonStore = useCommonStore()
    const advertisingStore = useAdvertisingStore()
    if (commonStore.curStore.profileList.length === 0 && !to.meta.noRefreshToken) {
      await commonStore.getProfileList(appStore.chooseAccounts)
    }
    if (!advertisingStore.supportInfo && !to.meta.noRefreshToken) {
      await advertisingStore.GetCheckSupportInfo()
    }
    // 欢迎页 / 引导页拦截
    const excludeRoute = ["/oauth2/redirect", "/Guide", "/Binding", "/Notification", "/Account/MyAccount", "/login", "/login/loginwithsso", "loginoauth/redirect", "/oauth2/vendorredirect"]
    const guidanceSetIncomplete = localStorage.getItem(`${userStore.user.userId}_SettingState`) != "1"
    if (!guidanceSetIncomplete && to.path === "/Guidance") {
      // 如果引导设置已全部完成, 且还要跳转至引导页, 则重定向至 MyAccount 页
      next("/Account/MyAccount")
    }
    if (!excludeRoute.includes(to.path) && to.path !== "/Guidance") {
      let profiles = JSON.parse(sessionStorage.getItem("userProfiles"))
      try {
        if (!profiles || profiles.length === 0) {
          profiles = await commonStore.getProfilesPlatform()
        }
      } catch (err) {
        sessionStorage.clear()
        throw new Error(err)
      }
      let sovUrl = [
        "/Analysis/Competitive",
        "/Analysis/SOVDashboard",
        "/Analysis/SOVBrand",
        "/Analysis/SOVKeyword",
        "/Analysis/SOVAsin",
        "/Analysis/SOVInsight",
        "/Analysis/ManageKeywordTag",
        "/Analysis/CompetitorAsinAnalysis",
        "/Analysis/BrandsMapping",
        "/Analysis/KeywordDetail"
      ]
      let ignoreSov = !(sovUrl.includes(to.path) && userStore.user.clientId == 3323)
      if (profiles?.length === 0 && ignoreSov) {
        localStorage.setItem(`${userStore.user.userId}_NoneAuthorized`, true)
        localStorage.setItem(`${userStore.user.userId}_BindProfile`, false)
        next("/Guide")
      } else {
        localStorage.setItem(`${userStore.user.userId}_NoneAuthorized`, false)
        localStorage.setItem(`${userStore.user.userId}_BindProfile`, true)
        // 是否完成引导设置
        if (guidanceSetIncomplete) {
          next("/Guide")
        }
      }
    }
  }
}
