import request from "@pacvue/frame/request"
import { project_rule_api, project_rule_engine_api } from "./index"

const dataurl = project_rule_api
const ruleEngineUrl = project_rule_engine_api

// createCategory
export function createCategory(data = {}) {
  return request({
    url: `${dataurl}/template/category`,
    method: "POST",
    data: data
  })
}
// deleteCategory
export function deleteCategory(categoryId) {
  return request({
    url: `${dataurl}/template/category/${categoryId}`,
    method: "DELETE"
  })
}
// editCategory
export function editCategory(data = {}) {
  return request({
    url: `${dataurl}/template/editCategory`,
    method: "POST",
    data: data
  })
}
// getCategory
export function getCategory(data = {}) {
  return request({
    url: `${dataurl}/template/getCategory`,
    method: "POST",
    data: data
  })
}

// createTemplate
export function createTemplate(data = {}) {
  return request({
    url: `${dataurl}/template`,
    method: "POST",
    data: data
  })
}
// editTemplate
export function editTemplate(data = {}) {
  return request({
    url: `${dataurl}/template/editTemplate`,
    method: "POST",
    data: data
  })
}
// deleteTemplate
export function deleteTemplate(templateId) {
  return request({
    url: `${dataurl}/template/${templateId}`,
    method: "DELETE"
  })
}
// moveTemplate
export function moveTemplate(data = {}) {
  return request({
    url: `${dataurl}/template/moveTemplate`,
    method: "POST",
    data: data
  })
}
// getTemplate
export function getTemplate(data = {}) {
  return request({
    url: `${dataurl}/template/getTemplate`,
    method: "POST",
    data: data
  })
}
// addFavorite
export function addFavorite(data = {}) {
  return request({
    url: `${dataurl}/template/addFavorite`,
    method: "POST",
    data: data
  })
}

// getOwners
export function getOwners(clientId) {
  return request({
    url: `${dataurl}/template/getOwners`,
    method: "GET"
  })
}

// preview
export function preview(data) {
  return request({
    url: `${ruleEngineUrl}/preview`,
    method: "POST",
    data
  })
}

export function getAllRequirementMetrics() {
  return request({
    url: `${dataurl}/config/requirementMetrics`,
    method: "GET"
  })
}
