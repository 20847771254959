const productline = localStorage.getItem("productline")

const NODE_ENV = "dev"
let project_rule_api = "https://api.pacvue.com/rule-api"
let project_rule_engine_api = `https://api.pacvue.com/rule-engine${productline == "instacart" ? "" : "-" + productline}`
let project_rule_meta_api = `https://api.pacvue.com/meta-api-${(productline == "samsclub" && "samsclub2") || productline}`
let project_rule_sov_api = "https://api.pacvue.com/sov/api"
let project_rule_report_api = `https://api.pacvue.com/report-${productline}`
switch (NODE_ENV) {
  // case "development":
  //   project_rule_api = `https://api.pacvue.com/rule-api-dev`;
  //   project_rule_engine_api = `https://api.pacvue.com/rule-engine-dev`; // commerce rule
  //   project_rule_report_api = "https://api.pacvue.com/report-amazon-dev";
  //   project_rule_sov_api = "https://api.pacvue.com/sov/api";
  //   break;
  case "dev":
    project_rule_api = `https://api.pacvue.com/rule-api-dev`
    project_rule_engine_api = `https://api.pacvue.com/rule-engine-${((productline == "doordash" || productline == "samsclub") && productline) || "dev"}`
    project_rule_report_api = "https://api.pacvue.com/report-amazon-dev"
    project_rule_sov_api = "https://api.pacvue.com/sov-phs/api"
    break
  case "devCN":
    project_rule_api = `https://api.pacvue.cn/rule-api-cntest`
    project_rule_meta_api = "https://api.pacvue.cn/meta-api-amazon"
    project_rule_engine_api = "https://api.pacvue.cn/rule-engine"
    project_rule_sov_api = "https://api.pacvue.cn/sov-walmart/api"
    project_rule_report_api = "https://api.pacvue.cn/report-amazon-dev"
    break
  case "test":
    project_rule_api = `https://api.pacvue.com/rule-api-amazon`
    project_rule_engine_api = `https://api.pacvue.com/rule-engine-${productline}`
    project_rule_report_api = "https://api.pacvue.com/report-amazon-dev"
    break
  case "productionCN":
    project_rule_api = `https://api.pacvue.cn/rule-api`
    project_rule_meta_api = `https://api.pacvue.cn/meta-api-${(productline == "samsclub" && "samsclub2") || productline}`
    project_rule_engine_api = `https://api.pacvue.cn/rule-engine${(productline != "amazon" && "-" + productline) || ""}`
    project_rule_sov_api = "https://api.pacvue.cn/sov-walmart/api"
    project_rule_report_api = `https://api.pacvue.cn/report-${productline}`
    break
  case "productionEU":
    project_rule_api = `https://api-eu.pacvue.com/rule-api`
    project_rule_meta_api = "https://api-eu.pacvue.com/meta-api-amazon"
    project_rule_engine_api = "https://api-eu.pacvue.com/rule-engine-amazon"
    project_rule_sov_api = "https://api-eu.pacvue.com/sov/api"
    project_rule_report_api = "https://api-eu.pacvue.com/report-amazon"
    break
  case "productionPHS":
    project_rule_api = `https://api.pacvue.com/rule-api-phs`
    project_rule_meta_api = "https://api.pacvue.com/meta-api-amazon-phs"
    project_rule_engine_api = "https://api.pacvue.com/rule-engine-amazon-phs"
    project_rule_sov_api = "https://api.pacvue.com/sov-phs/api"
    project_rule_report_api = "https://api.pacvue.com/report-amazon-phs"
    break
  case "production":
    project_rule_api = "https://api.pacvue.com/rule-api"
    break
}

export { project_rule_api, project_rule_engine_api, project_rule_meta_api, project_rule_sov_api, project_rule_report_api }
