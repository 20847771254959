import Main from "@pacvue/frame/layout/Main.vue"

export default {
  router: {
    path: "/Optimization/budgetschedule",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Optimization/budgetschedule",
        name: "Budget Scheduler",
        component: () => import("@budgetschedulev3/index.vue"),
        mata: {
          alias: "Opt_Budget"
        }
      },
      {
        path: "/Optimization/budgetschedule/setting",
        name: "budgetscheduleSetting",
        component: () => import("@budgetschedulev3/pages/SchedulerSetting.vue"),
        mata: {
          parent: "Budget Scheduler",
          alias: "Opt_Budget"
        }
      },
      {
        path: "/Optimization/budgetschedule/applyinfo",
        name: "budgetscheduleApplyinfo",
        component: () => import("@budgetschedulev3/pages/ApplyInfo.vue"),
        mata: {
          parent: "Budget Scheduler",
          alias: "Opt_Budget"
        }
      }
    ]
  }
}
