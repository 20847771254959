import state from "./state"
import getters from "./getters"
import actions from "./actions"
var modules = {}

export default {
  state,
  getters,
  actions
}
