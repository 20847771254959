import { project_rule_api, project_rule_engine_api, project_rule_meta_api } from "./index"
import request from "@pacvue/frame/request"

const dataurl = project_rule_api
const metaUrl = project_rule_meta_api
const ruleEngineUrl = project_rule_engine_api

// 获取列表数据
export function getRuleList(data = {}) {
  return request({
    url: `${dataurl}/definition/getRule`,
    method: "POST",
    data: data
  })
}
export function getRuleTargetInfoByRuleIds(data) {
  return request({
    url: `${dataurl}/definition/getRuleTargetInfoByRuleIds`,
    method: "POST",
    data: data
  })
}
export function changeRuleState(data) {
  return request({
    url: `${dataurl}/definition/changeStatus`,
    method: "POST",
    data: data
  })
}
export function changeRuleinAIState(data) {
  return request({
    url: `${metaUrl}/entity/hasAICampaign`,
    method: "POST",
    data: data
  })
}
//获取table中所出现的campaign与tag
export function getTarget(data) {
  return request({
    url: `${dataurl}/definition/getTarget`,
    method: "POST",
    data: data
  })
}
//获取列表出现的
export function getOwner(data) {
  return request({
    url: `${dataurl}/definition/getOwners`,
    method: "POST",
    data: data
  })
}
export function getApplyRule(data) {
  return request({
    url: `${dataurl}/definition/getApplyRule`,
    method: "POST",
    data: data
  })
}
export function ruleRun(ruleDefinitionId) {
  return request({
    url: `${ruleEngineUrl}/run/${ruleDefinitionId}`,
    method: "POST"
  })
}
export function ruleRunTest(ruleDefinitionId) {
  return request({
    url: `${ruleEngineUrl}/run/test/${ruleDefinitionId}`,
    method: "POST",
    responseType: "blob"
  })
}
//批量run now
export function ruleRunBulk(data) {
  return request({
    url: `${ruleEngineUrl}/run/bulk`,
    method: "POST",
    data: data
  })
}
//获取所有的
export function getOwners(clientId) {
  return request({
    url: `${metaUrl}/users/${clientId}`,
    method: "get"
  })
}
export function changeOwners(data) {
  return request({
    url: `${dataurl}/definition/changeOwners`,
    method: "POST",
    data: data
  })
}
//下载
export function exportRuleList(data) {
  return request({
    url: `${dataurl}/definition/export`,
    method: "POST",
    data: data,
    responseType: "blob"
  })
}
//获取appliedObjected
export function getRuleTargetInfo(ruleId) {
  return request({
    url: `${dataurl}/definition/getRuleTargetInfo?ruleId=${ruleId}`,
    method: "get"
  })
}
//Preview
export function reviewRule(ruleDefinitionId) {
  return request({
    url: `${ruleEngineUrl}/review/${ruleDefinitionId}`,
    method: "get"
  })
}

export function updateAppliedObj(data) {
  return request({
    url: `${dataurl}/definition/updateAppliedObj`,
    method: "post",
    data
  })
}
//获取result
export function getRuleAction() {
  return request({
    url: `${dataurl}/config/RuleActionMetrics`,
    method: "get"
  })
}
