//import modules from './modules/index'

const watchFields = ["selectedProfiles", "sovOrder", "selectedCamTags", "selectedPorfolios", "selectedCampaigns", "selectedAdgroups", "defaultReportQuery", "commonfilters"]

export default {
  state: () => {
    return {
      platformName: "amazon",
      profileList: [],
      profileListAccount: [],
      allProfileList: [],
      selectedProfiles: [],
      sovOrder: "shareOfShelf",
      intelligenceProfile: [],
      intelligenceTopData: "",
      selectedCamTags: [],
      selectedPortfolios: [],
      selectedCampaigns: [],
      selectedAdgroups: [],
      sdProfiles: [],
      mpReportFilters: {},
      mpLoading: false
    }
  },
  getters: {
    //testName: (state) => state.advertising.testName
  },
  actions: {
    TEST_FN() {
      console.error("我的平台是：" + this.platformName)
    },
    setDefaultReportQuery(playload) {
      this.defaultReportQuery = playload || {}
    },
    setProfileList(playload) {
      this.profileList = playload || []
    },
    setProfileListAccount(payload) {
      this.profileListAccount = payload || []
    },
    setAllProfileList(playload) {
      this.allProfileList = playload || []
    },
    setSelectedProfiles(playload) {
      var profileIds = playload || []
      this.selectedProfiles = profileIds
    },
    setSovOrder(val) {
      this.sovOrder = val
    },
    setIntelligenceProfile(val) {
      this.intelligenceProfile = val
    },
    setIntelligenceTopData(val) {
      this.intelligenceTopData = val
    },
    setSelectedCamTags(playload) {
      var CampaignTags = playload || []
      this.selectedCamTags = CampaignTags
    },
    setSelectedPortfolios(playload) {
      var Porfolios = playload || []
      this.selectedPortfolios = Porfolios
    },
    setSelectedCampaigns(playload) {
      var Campaigns = playload || []
      this.selectedCampaigns = Campaigns
    },
    setSelectedAdgroups(playload) {
      var Adgroups = playload || []
      this.selectedAdgroups = Adgroups
    },
    setUserPersist() {
      var userid = localStorage.getItem("uid")
      watchFields.forEach((fieldName) => {
        var storeKey = userid + "_" + fieldName
        var storeStr = localStorage.getItem(storeKey)
        if (storeStr) {
          this[fieldName] = JSON.parse(storeStr)
        }
      })
    },
    setSdProfiles(playload) {
      this.sdProfiles = playload || []
    },
    setCommonFilters(playload) {
      //设置页面公用的filter变量
      playload = playload || {}
      this.commonfilters = { ...this.commonfilters, ...playload }
    }
  },
  //开启数据缓存
  persist: {
    enabled: true,
    watchFields: watchFields
  }
}
