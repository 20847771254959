import request from "@pacvue/frame/request"
const VITE_APP_APIREPORT = import.meta.env.VITE_APP_APIREPORT
const VITE_APP_META = import.meta.env.VITE_APP_META
const VITE_APP_PRODUCT_3P = import.meta.env.VITE_APP_PRODUCT_3P
const VITE_APP_MENU_API = import.meta.env.VITE_APP_MENU_API
export function getMenus() {
  return request({
    url: `${VITE_APP_MENU_API}user/menus`,
    method: "get"
  })

  // return Promise.resolve([
  //   {
  //     alias: 'Home',
  //     children: [],
  //     icon: 'Home',
  //     menuId: 1,
  //     menuName: 'Home',
  //     parentId: 0,
  //     url: '/Home'
  //   },
  //   {
  //     alias: 'ProductCenter',
  //     children: [
  //       {
  //         alias: 'ProductDashboard',
  //         children: [],
  //         icon: '',
  //         menuId: 301,
  //         menuName: 'Product Dashboard',
  //         parentId: 3,
  //         url: '/ProductCenter/ProductDashboard'
  //       },
  //       {
  //         alias: 'ProductListing',
  //         children: [],
  //         icon: '',
  //         menuId: 302,
  //         menuName: 'Product Listing',
  //         parentId: 3,
  //         url: '/ProductCenter/ProductListing'
  //       },
  //       {
  //         alias: 'Order',
  //         children: [],
  //         icon: '',
  //         menuId: 303,
  //         menuName: 'Order',
  //         parentId: 3,
  //         url: '/ProductCenter/Order'
  //       }
  //     ],
  //     icon: 'ProductCenter',
  //     menuId: 3,
  //     menuName: 'Product Center',
  //     parentId: 0,
  //     url: '/ProductCenter'
  //   }
  // ])
}
export function getProfile(data) {
  var platform = localStorage.getItem("productline") || "amazon"
  var postline = ["amazon", "kroger", "walmart"]

  return request({
    url: `${VITE_APP_META}filter/profiles`,
    method: "post",
    data: {
      advertiserIds: data
    }
  })
}
export function getAmazonList() {
  return request({
    url: `${VITE_APP_APIREPORT}/api/User/V3/GetAmazonList`,
    method: "post"
  })
}
export function getPacvueUniversityUrl() {
  return request({
    url: `${VITE_APP_APIREPORT}/api/sso/V3/GetPacvueUniversityUrl`,
    method: "get"
  })
}
export function getZendeskUrl() {
  return request({
    url: `${VITE_APP_APIREPORT}/api/sso/V3/GetZendeskUrl`,
    method: "get"
  })
}
export function getUserPermission(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getUserPermission`,
    method: "post",
    data
  })
}
