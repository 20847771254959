import useAppStore from "@pacvue/frame/frameStore"
import useUserStore from "@pacvue/frame/user"
import rule from "./rule.js"
import common from "./common/index"
import amazon from "./amazon/index"
import walmart from "./walmart/index"
import samsclub from "./samsclub/index"
import event from "./event/index"
import { useStoreModule } from "./util"
const modules = { common, amazon, walmart, event, rule, samsclub }
var { useCommonStore, useAmazonStore, useWalmartStore, useEventStore, useRuleStore, useSamsclubStore } = useStoreModule(modules)

export { useAppStore, useUserStore, useCommonStore, useAmazonStore, useWalmartStore, useEventStore, useRuleStore, useSamsclubStore }
