import request from "@pacvue/frame/request"
import { PacvueRequestTagFormat } from "@/utils/common-util"
const { VITE_APP_WALMART_BASE_URL, VITE_APP_SHARETAGURL } = import.meta.env

export function campaignTagsByProfile(mode) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + mode + "Tags",
    method: "get"
  })
}
export function getTags(mode) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + mode + "Tags",
    method: "get"
  })
}
export function changeTagName(query, data, mode) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + mode + "Tags/name?id=" + query,
    data,
    method: "patch"
  })
}
export function putItemTagName(data, mode) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + mode + "Tags",
    method: "put",
    data
  })
}
export function putKeywordTagName(query, data, mode) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + mode + "Tags?id=" + query,
    method: "put",
    data
  })
}
export function deleteTag(query, mode) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + mode + "Tags/?id=" + query,
    fullData: true,
    method: "delete"
  })
}
export function postTags(data, mode) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + mode + "Tags",
    data,
    method: "post"
  })
}

export function getTagsCampaigns(query) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "CampaignTags/campaigns",
    method: "get",
    params: query
  })
}
export function putBulkTagCampaign(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "CampaignTags/BulkTagCampaign",
    method: "put",
    data
  })
}
export function dailyBudgetCampaignWithPacingTagCheck(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "CampaignTags/dailyBudgetCampaignWithPacingTagCheck",
    method: "post",
    data
  })
}
export function putCampaignTags(id, data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "CampaignTags?id=" + id,
    method: "put",
    data
  })
}
export function GetCampaignSubTagData(parentId, data) {
  var url = `Report/subTag?parentId=${parentId}`
  return request({
    url: VITE_APP_WALMART_BASE_URL + url,
    data,
    method: "post"
  })
}
export function getCampaignTags(mode, params = {}) {
  var requestTag = request({
    url: VITE_APP_WALMART_BASE_URL + mode + "Tags",
    method: "get"
  })
  return PacvueRequestTagFormat({ requestTag, params })
}

export function getCampaignTag(params = {}) {
  var requestTag = request({
    url: VITE_APP_WALMART_BASE_URL + "CampaignTags",
    method: "get"
  })
  return PacvueRequestTagFormat({ requestTag, params })
}

export function deleteCampaignTag(id) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "CampaignTags?id=" + id,
    fullData: true,
    method: "delete"
  })
}
export function deleteFromCampaignTag(data) {
  return request({
    url: VITE_APP_SHARETAGURL + "CampaignTags/campaigns",
    method: "delete",
    data
  })
}

export function deleteCampaignTagsBulk(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "CampaignTags/BulkCampaignTag",
    method: "delete",
    fullData: true,
    data
  })
}

export function setTagTargets(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "CampaignTags/targetSetting",
    method: "put",
    data
  })
}

export function UpdateTagOwner(data) {
  return request({
    url: VITE_APP_WALMART_BASE_URL + "CampaignTags/UpdateTagOwner",
    method: "put",
    data
  })
}
